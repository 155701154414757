import {useUserStore} from "~/admin/central-admin/store/useUserStore";

export function isAuth(to, from, next) {
    const {isUser} = useUserStore();

    if (!isUser) {
        return next('sign-in');
    }

    return next();
}
