<script setup>
import {onMounted, reactive} from "vue";
const data = reactive({
    csrf_token: '',
});

onMounted(() => {
    data.csrf_token = window.csrf_token;
})
</script>

<template>
    <div class="page main-signin-wrapper">
        <div class="d-flex header-setting-icon demo-icon fa-spin" data-bs-toggle="offcanvas"
             data-bs-target="#switcher-canvas"><a class="nav-link icon" href="javascript:;"><i
            class="fe fe-settings settings-icon text-dark"></i></a>
        </div>
        <!-- Row -->
        <div class="row signpages text-center">
            <div class="col-md-12 my-auto">
                <div class="card">
                    <div class="row row-sm">

                        <div class="col login_form">
                            <div class="main-container container-fluid">
                                <div class="row row-sm">
                                    <div class="card-body mt-2 mb-2">
                                        <div class="clearfix"></div>
                                        <form METHOD="post" action="/login">
                                            <input type="hidden" name="_token" :value="data.csrf_token" />
                                            <h5 class="text-start mb-2">Войдите в свой аккаунт</h5>
                                            <p class="mb-4 text-muted fs-13 ms-0 text-start">
                                                Войдите, чтобы создавать, открывать для себя и общаться с мировым сообществом
                                            </p>
                                            <div class="form-group text-start">
                                                <label>Email</label>
                                                <input class="form-control" name="email" placeholder="Enter your email" type="email" />
                                            </div>
                                            <div class="form-group text-start">
                                                <label>Password</label>
                                                <input class="form-control" name="password" placeholder="Enter your password" type="password" />
                                            </div>
                                            <button class="btn ripple btn-primary d-grid w-100">
                                                Войти
                                            </button>
                                        </form>
                                        <div class="text-start mt-5 ms-0">
                                            <div class="mb-1">
                                                <router-link :to="`/`">Forgot password?</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Row -->
    </div>
</template>
