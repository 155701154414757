import { defineStore } from 'pinia';
import {USER_NOTIFICATION_REQUEST_FORM_TYPE, USER_SETTING_SHOW_NOTIFICATION} from "~/store/user/user_variables";
import {REQUEST_FORM_USER_STATUS_CANCEL, REQUEST_FORM_USER_STATUS_FINISHED} from "~/store/constant_variables";

export const useUserStore = defineStore('auth-user-store', {
    state: () => {
        return {
            user: null,
        }
    },

    getters:{
        isUser: state => !!state.user,
        getUser: state => state.user,
        getId: state => state.user.id,
        getName: state => state.user?.name,
        getAvatarPath: state => state.user.avatar_path,
    },

    actions:{
        setUser(user) {
            this.user = user;
        },
    },
});
