import AppComponent from '~/admin/central-admin/CentralApp.vue';

import "/resources/sass/admin/admin-base-styles.scss";
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { createApp } from "vue";
import { createPinia } from 'pinia';
import router from '~/admin/central-admin/router/index';
import {useUserStore} from "~/admin/central-admin/store/useUserStore";

const App = {}
const app = createApp(App);
const pinia = createPinia()



app.component('app', AppComponent);
app.component("simplebar", simplebar);
app.use(pinia);
app.use(router);

const {setUser} = useUserStore();

if (window?.user ?? null) {
    setUser(window.user);
}

if (document.querySelector('#central-admin')) {
    app.mount('#central-admin');
}
