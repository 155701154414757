<script setup>
import {onMounted, reactive, ref} from "vue";
import AdminRecursiveMenu from "~/admin/components/sidebar/AdminRecursiveMenu.vue";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();

const props = defineProps({
    dataMenu: {
        type: Array,
        default: [],
    }
});

const refPreventpagejump = ref(null);
const data = reactive({
    setMenu: false,
    menuOverflowed: false,
    previousUrl: '/',
    WindowPreSize: [window.innerWidth],
    oldLi: undefined,
    level: 0,
    Nested: [],
    PreviousNumber: null,
    hasParent: false,
    hasParentLevel: 0,
    url: import.meta.env.BASE_URL,
});

onMounted(() => {
    window.addEventListener('resize', menuResizeFn);
    window.addEventListener('resize',checkHoriMenu);
    checkHoriMenu();
    window.addEventListener('scroll',menuscrollFn);
    let mainContent = document.querySelector('.main-content')
    // Adding the mainContentFn after the component created.
    mainContent.addEventListener('click', mainContentFn);

    // Used to check on mounting face to close the menu.
    window.innerWidth < 992 && document.documentElement.setAttribute('data-toggled', 'close');
    document.documentElement.getAttribute('data-nav-layout') === 'horizontal' && closeMenuFn();

    // Select the target element
    const targetElement = document.documentElement;

    // Create a MutationObserver instance
    const observer = new MutationObserver(handleAttributeChange);

    // Configure the observer to watch for attribute changes
    const config = { attributes: true };

    // Start observing the target element
    observer.observe(targetElement, config);
});

const handleAttributeChange = (mutationsList, observer) => {
    for (const mutation of mutationsList) {

        if (mutation.type === 'attributes' && mutation.attributeName === 'data-nav-layout') {
            const newValue = mutation.target.getAttribute('data-nav-layout');
            if (newValue === 'vertical') {
                let currentPath = route.path.endsWith('/') ? route.path.slice(0, -1) : route.path;
                currentPath = !currentPath ? '/dashboard/ecommerce' : currentPath;
                setMenuUsingUrl(currentPath);
            } else {
                closeMenuFn();
            }
        }
    }
};

const setMenuUsingUrl = (currentPath) => {
    data.hasParent = false;
    data.hasParentLevel = 1;
    // Check current url and trigger the setSidemenu method to active the menu.
    const setSubmenuRecursively = (items) => {
        items?.forEach((item) => {
            if (item.path === '') {
                return;
            }

            if (item.path === currentPath) {
                setSubmenu(null, item);
            }

            setSubmenuRecursively(item.children);
        });
    };
    setSubmenuRecursively(props.dataMenu);
};

const setSubmenu = (event, targetObject, menuData = props.dataMenu) => {
    let html = document.documentElement;
    if (html.getAttribute('data-nav-style') !== "icon-hover" && html.getAttribute('data-nav-style') != "menu-hover") {
        if (!event?.ctrlKey) {
           data.setMenu = true;

            for (const item of menuData) {

                if (item === targetObject) {
                    item.active = true;
                    item.selected = true;
                    setMenuAncestorsActive(item);
                } else if (!item.active && !item.selected) {
                    item.active = false; // Set active to false for items not matching the target
                    item.selected = false; // Set active to false for items not matching the target
                } else {
                    removeActiveOtherMenus(item);
                }

                item.children && item.children.length > 0 && setSubmenu(event, targetObject, item.children);
            }
            data.setMenu = false;
        }
    }
};

const  getParentObject = (obj, childObject) =>  {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object' && JSON.stringify(obj[key]) === JSON.stringify(childObject)) {
                return obj; // Return the parent object
            }

            if (typeof obj[key] === 'object') {
                const parentObject = getParentObject(obj[key], childObject);
                if (parentObject !== null) {
                    return parentObject;
                }
            }
        }
    }
    return null; // Object not found
}

const setMenuAncestorsActive = (targetObject) => {
    const parent = getParentObject(props.dataMenu, targetObject);
    let html = document.documentElement;
    if (parent) {
        data.hasParentLevel > 2 && (data.hasParent = true);

        parent.active = true;
        parent.selected = true;
        data.hasParentLevel += 1;
        setMenuAncestorsActive(parent);
    }

     if (!data.hasParent && html.getAttribute('data-vertical-style') === 'doublemenu') {
         html.setAttribute('data-toggled', 'double-menu-close');
    }
};

const removeActiveOtherMenus = (item) => {
    if (item) {
        if (Array.isArray(item)) {
            for (const val of item) {
                val.active = false;
                val.selected = false;
            }
        }
        item.active = false;
        item.selected = false;

        if (item.children && item.children.length > 0) {
            removeActiveOtherMenus(item.children);
        }
    }
};

const menuResizeFn = () => {
   data.WindowPreSize.push(window.innerWidth);
    if (data.WindowPreSize.length > 2) { data.WindowPreSize.shift() }
    if (data.WindowPreSize.length > 1) {
        if ((data.WindowPreSize[data.WindowPreSize.length - 1] < 992) && (data.WindowPreSize[data.WindowPreSize.length - 2] >= 992)) {
            // less than 992;
            let html = document.documentElement;
            html.setAttribute('data-toggled', 'close');
        }

        if ((data.WindowPreSize[data.WindowPreSize.length - 1] >= 992) && (data.WindowPreSize[data.WindowPreSize.length - 2] < 992)) {
            let html = document.documentElement;
            // greater than 992
            if (html.getAttribute('data-vertical-style') == 'doublemenu') {
                html.setAttribute('data-toggled', 'double-menu-close');
            } else {
                html.removeAttribute('data-toggled')
            }
        }
    }
};

const mainContentFn = () => {
    // Used to close the menu in Horizontal and small screen
    let html = document.documentElement;
    if (html && window.innerWidth < 992) {
        html.setAttribute('data-toggled', 'close');
    } else if (html.getAttribute('data-nav-layout') == 'horizontal' || html.getAttribute('data-nav-style') == 'menu-click' || html.getAttribute('data-nav-style') == 'icon-click') {
       closeMenuFn();
    }
};

const checkHoriMenu = () => {
    let menuNav = document.querySelector('.main-sidebar');
    let mainMenu = document.querySelector('.main-menu');
    let slideLeft = document.querySelector('.slide-left');
    let slideRight = document.querySelector('.slide-right');

    // Show/Hide the arrows
    if (mainMenu && menuNav && slideRight && slideLeft) {
        let marginRightValue= Math.ceil(Number(window.getComputedStyle(mainMenu).marginInlineStart.split('px')[0]));
        if (mainMenu.scrollWidth > menuNav.offsetWidth) {
            slideRight?.classList.remove('d-none');
            slideLeft?.classList.add('d-none');
        }
        else {
            slideRight?.classList.add('d-none');
            slideLeft?.classList.add('d-none');
            mainMenu.style.marginLeft = '0px';
            mainMenu.style.marginRight = '0px';
        }
        if (marginRightValue == 0) {
            slideLeft?.classList.add('d-none');
        }
        else {
            slideLeft?.classList.remove('d-none');
        }
    }

};

const menuscrollFn = () => {
    let html = document.documentElement;
    let navLayout = html.getAttribute('data-nav-layout') === "horizontal";
    let menuPosition = html.getAttribute('data-menu-position') === "scrollable";
    let header = document.querySelector('.app-header')?.clientHeight || 0;
    window.onscroll = () => {
        if (!menuPosition && refPreventpagejump && navLayout && window.innerWidth >= 992) {
            if (window.scrollY > header) {
                refPreventpagejump.style.height = header + 'px';
                data.menuOverflowed = true;
            } else {
                refPreventpagejump.style.height = 0 + 'px';
                data.menuOverflowed = false;
            }
        }
    };
};

const closeMenuFn = () => {
    const closeMenuRecursively = (items) => {
        items?.forEach((item) => {
            item.active = false;
            closeMenuRecursively(item.children);
        });
    };
    closeMenuRecursively(props.dataMenu);
};

const HoverToggleInnerMenuFn = (event, item) => {
    let html = document.documentElement;
    let element = event.target;
    if (element && html.getAttribute("data-nav-layout") == 'horizontal' && (html.getAttribute("data-nav-style") == 'menu-hover' || html.getAttribute("data-nav-style") == 'icon-hover')) {
        const listItem = element.closest("li");
        if (listItem) {
            // Find the first sibling <ul> element
            const siblingUL = listItem.querySelector("ul");
            let outterUlWidth = 0;
            let listItemUL = listItem.closest('ul:not(.main-menu)');

            while (listItemUL) {
                listItemUL = listItemUL.parentElement.closest('ul:not(.main-menu)');
                if (listItemUL) {
                    outterUlWidth += listItemUL.clientWidth;
                }
            }

            if (siblingUL) {
                // You've found the sibling <ul> element
                let siblingULRect = listItem.getBoundingClientRect();
                if (html.getAttribute('dir') == 'rtl') {
                    if ((siblingULRect.left - siblingULRect.width - outterUlWidth + 150 < 0 && outterUlWidth < window.innerWidth) && (outterUlWidth + siblingULRect.width + siblingULRect.width < window.innerWidth)) {
                        item.dirchange = true;
                    } else {
                        item.dirchange = false;
                    }
                } else {
                    if ((outterUlWidth + siblingULRect.right + siblingULRect.width + 50 > window.innerWidth && siblingULRect.right >= 0) && (outterUlWidth + siblingULRect.width + siblingULRect.width < window.innerWidth)) {
                        item.dirchange = true;
                    } else {
                        item.dirchange = false;
                    }
                }
            }
        }
    }
};

const toggleSubmenu = (event, targetObject, menuData = props.dataMenu, level = data.level) => {
    let html = document.documentElement;
    let element = event.target;
    if (html.getAttribute('data-nav-style') != "icon-hover" && html.getAttribute('data-nav-style') != "menu-hover") {
        for (const item of menuData) {
            if (item === targetObject) {
                if (html.getAttribute('data-vertical-style') == 'doublemenu' && item.active && level == 1) { return }
                item.active = !item.active;
                if (item.active) {
                    closeOtherMenus(menuData, item);
                } else {
                    if (html.getAttribute('data-vertical-style') == 'doublemenu' && level == 1) {
                        html.setAttribute('data-toggled', 'double-menu-close');
                    }
                }
                setAncestorsActive(menuData, item, level);

            } else if (!item.active) {
                if (html.getAttribute('data-vertical-style') != 'doublemenu') {
                    item.active = false; // Set active to false for items not matching the target
                }
            }
            if (item.children && item.children.length > 0) {
                toggleSubmenu(event, targetObject, item.children, level);
            }
        }
        if (targetObject?.children && targetObject.active) {
            if (html.getAttribute('data-vertical-style') === 'doublemenu' && html.getAttribute('data-toggled') != 'double-menu-open') {
                html.setAttribute('data-toggled', 'double-menu-open');
            }
        }
        if (element && html.getAttribute("data-nav-layout") === 'horizontal' && (html.getAttribute("data-nav-style") == 'menu-click' || html.getAttribute("data-nav-style") == 'icon-click')) {
            const listItem = element.closest("li");
            if (listItem) {
                // Find the first sibling <ul> element
                const siblingUL = listItem.querySelector("ul");
                let outterUlWidth = 0;
                let listItemUL = listItem.closest('ul:not(.main-menu)');
                while (listItemUL) {
                    listItemUL = listItemUL.parentElement.closest('ul:not(.main-menu)');
                    if (listItemUL) {
                        outterUlWidth += listItemUL.clientWidth;
                    }
                }
                if (siblingUL) {
                    // You've found the sibling <ul> element
                    let siblingULRect = listItem.getBoundingClientRect();
                    if (html.getAttribute('dir') === 'rtl') {
                        if ((siblingULRect.left - siblingULRect.width - outterUlWidth + 150 < 0 && outterUlWidth < window.innerWidth) && (outterUlWidth + siblingULRect.width + siblingULRect.width < window.innerWidth)) {
                            targetObject.dirchange = true;
                        } else {
                            targetObject.dirchange = false;
                        }
                    } else {
                        if ((outterUlWidth + siblingULRect.right + siblingULRect.width + 50 > window.innerWidth && siblingULRect.right >= 0) && (outterUlWidth + siblingULRect.width + siblingULRect.width < window.innerWidth)) {
                            targetObject.dirchange = true;
                        } else {
                            targetObject.dirchange = false;
                        }
                    }
                }
                setTimeout(() => {
                    let computedValue = siblingUL.getBoundingClientRect();
                    if ((computedValue.bottom) > window.innerHeight) {
                        siblingUL.style.height = (window.innerHeight - computedValue.top - 8) + 'px';
                        siblingUL.style.overflow = 'auto';
                    }
                }, 100);
            }
        }
    }
};

const closeOtherMenus = (menuData, targetObject) => {
    for (const item of menuData) {
        if (item !== targetObject) {
            item.active = false;
            if (item.children && item.children.length > 0) {
                closeOtherMenus(item.children, targetObject);
            }
        }
    }
};

const setAncestorsActive = (menuData, targetObject, level) => {
    let html = document.documentElement;
    const parent = findParent(menuData, targetObject);
    if (parent) {
        parent.active = true;
        if (parent.active) {
            html.setAttribute('data-toggled', 'double-menu-open');
        }

        setAncestorsActive(menuData, parent, level);
    } else {
        if (html.getAttribute('data-vertical-style') == 'doublemenu' && level == 1) {
            html.setAttribute('data-toggled', 'double-menu-close');
        }

    }
};

const findParent = (menuData, targetObject) => {
    for (const item of menuData) {
        if (item.children && item.children.includes(targetObject)) {
            return item;
        }
        if (item.children && item.children.length > 0) {
            const parent = findParent(item.children, targetObject);
            if (parent) {
                return parent;
            }
        }
    }
    return null;
};
</script>

<template>
    <div id="responsive-overlay" @click="mainContentFn"></div>
    <aside class="app-sidebar sticky" :class="{'sticky-pin' : data.menuOverflowed}" id="sidebar">
        <!-- Start::main-sidebar-header -->
        <div class="main-sidebar-header">
            <router-link  to='/' class="header-logo">
                <img src="/images/brand-logos/desktop-logo.png" alt="logo" class="desktop-logo">
                <img src="/images/brand-logos/toggle-logo.png" alt="logo" class="toggle-logo">
                <img src="/images/brand-logos/desktop-dark.png" alt="logo" class="desktop-dark">
                <img src="/images/brand-logos/toggle-dark.png" alt="logo" class="toggle-dark">
                <img src="/images/brand-logos/desktop-white.png" alt="logo" class="desktop-white">
                <img src="/images/brand-logos/toggle-white.png" alt="logo" class="toggle-white">
            </router-link>
        </div>
        <!-- End::main-sidebar-header -->

        <!-- Start::main-sidebar -->
        <simplebar data-simplebar-auto-hide="false" class="main-sidebar" id="sidebar-scroll">
            <!-- <div class="hor-scroll"> -->
            <!-- Start::nav -->
            <nav class="main-menu-container nav nav-pills flex-column sub-open">
                <div class="slide-left" id="slide-left" @click="leftArrowFn">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"> <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path> </svg>
                </div>
                <ul class="main-menu">
                    <li v-for="(mainmenuItem, index) in props.dataMenu" :key="index"
                        :class="`${mainmenuItem.headTitle ? 'slide__category' : ''} ${mainmenuItem?.type == 'link'? 'slide' : ''} ${mainmenuItem?.type == 'empty'? 'slide' : ''} ${mainmenuItem?.type == 'sub' ? 'slide has-sub' : ''} ${mainmenuItem?.active && mainmenuItem?.type == 'sub' ? 'open' : ''} ${mainmenuItem?.selected ? 'active' : ''}`">
                        <template v-if="mainmenuItem.headTitle">
                            <span class="category-name">{{ mainmenuItem.headTitle }}</span>
                        </template>
                        <template v-if="mainmenuItem?.type === 'link'">
<!--                            <router-link  :to="mainmenuItem.path" class="side-menu__item" :class="`${mainmenuItem.selected ? 'active' : ''}`" ><span class="shape1"></span><span class="shape2"></span>-->
<!--                                <i :class="`${mainmenuItem.icon} side-menu__icon`"></i>-->
<!--                                <span class="side-menu__label">{{ mainmenuItem.title }}<span v-if="mainmenuItem.badge" :class="`badge ${mainmenuItem.badgeColor} ms-1`">{{ mainmenuItem.badge }}</span></span>-->
<!--                            </router-link >-->
                        </template>
                        <template v-if="mainmenuItem?.type === 'empty'">
<!--                            <router-link  href="javascript:;" class="side-menu__item"><span class="shape1"></span><span class="shape2"></span>-->
<!--                                <i :class="`${mainmenuItem.icon} side-menu__icon`"></i>-->
<!--                                <span class="side-menu__label">{{ mainmenuItem.title }}<span v-if="mainmenuItem.badge" :class="`badge ${mainmenuItem.badgeColor} ms-1`">{{ mainmenuItem.badge }}</span></span>-->
<!--                            </router-link >-->
                        </template>
                        <template v-if="mainmenuItem?.type === 'sub'">
                            <AdminRecursiveMenu
                                :menuData="mainmenuItem"
                                :toggleSubmenu="toggleSubmenu"
                                :HoverToggleInnerMenuFn="HoverToggleInnerMenuFn"
                                :level="data.level + 1"
                            />
                        </template>
                    </li>
                </ul>

                <div class="slide-right" @click="rightArrowFn" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"> <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path> </svg></div>
            </nav>
            <!-- End::nav -->
            <!-- </div>  -->
            <!-- </div> -->
        </simplebar>
        <!-- End::main-sidebar -->

    </aside>
    <div :ref="el => refPreventpagejump = el" class="prevent-page-jump"></div>
</template>
